button[class^="Calendar-weekDay-"], [class*="Calendar-weekDay-"] {
    padding: 0 !important;
}

body {
    background-color: #eee !important
}

.btn-card {
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
}

.btn-card:hover {
    background-color: #ddd;
}

.btn-card.disabled {
    color: #bbb;
    cursor: not-allowed;
    pointer-events: none;
}

.btn-card.picked {
    font-weight: 700;
}

.auto-margin {
    margin: auto;

}

.center {
    text-align: center
}

.margin-top {
    margin-top: 15px;
}

.small-screen {
    max-width: 100vh;
    min-height: 50vh;
}

.btn-more {
    text-align: center;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
}
.btn-more:hover {
    background-color: #eee;
}

.stepFather div:nth-child(1) span {
    margin: 10px;
    margin-top: 12px;
}

.padding-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
}

.account_logo  {
    text-align: center;
    align-self: center;
    margin: auto;
    max-height: 80px !important;
}

