body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn-card {
  border: 1px solid #DDD;
  margin-bottom: 10px;
  padding: 17px 10px !important;
  text-align: center;
  letter-spacing: 1px;
  color: #666;
}
._addressField + ._addressField {
  margin-left: 20px !important;
}

._addressField {
  width: calc((100% - 40px) * 0.33);
}

.stepButton svg{font-size: 50px !important;}
.Calendar-weekDay-297 {
  padding: 0;
}
.workiz{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  bottom: 0px;
  position: "fixed";
}